<template>
  <div>
    <b-card-code title="Add Courier">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-row
                v-for="(item, index) in courierData.translations"
                :id="item.id"
                :key="item.id"
                ref="row"
              >
                <!-- translation language -->
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="language"
                    rules="required"
                  >
                    <b-form-group
                      label="Language"
                      label-for="language"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        :id="'language' + index"
                        v-model="tranLocaleSelect[index]"
                        :disabled="tranLocaleSelect[index].disa"
                        :state="tranLocaleSelect[index] === null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="localeOptions"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- translation name -->
                <b-col md="6">
                  <b-form-group label="Name" label-for="name">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-form-input
                        :id="'TranslationName' + index"
                        v-model="courierData.translations[index].name"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- translation description -->
                <b-col cols="12">
                  <b-form-group label="Description" label-for="description">
                    <validation-provider
                      #default="{ errors }"
                      name="description"
                      rules="required"
                    >
                      <vue-editor
                        :id="'translationDescription' + index"
                        v-model="courierData.translations[index].description"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Remove Button -->
                <b-col lg="2" md="3" class="mb-50">
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    v-show="!tranLocaleSelect[index].disa"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)"
                  >
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Delete</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
            </b-col>
            <!-- add new button -->
            <b-col sm="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                block
                @click="repeateAgain"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add New</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!-- active -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="active"
                rules="required"
              >
                <b-form-group
                  label="Active"
                  label-for="active"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="active"
                    v-model="activeSelect"
                    :state="activeSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="activeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <div>
                <b-form
                  ref="form"
                  :style="{ height: trHeight }"
                  class="repeater-form"
                  @submit.prevent="repeateAgain"
                >
                  <!-- Row Loop -->
                  <b-row
                    v-for="(item, index) in courierData.areas"
                    :id="item.id"
                    :key="item.id"
                    ref="row"
                  >
                    <!-- country -->
                    <b-col md="4">
                      <validation-provider
                        #default="{ errors }"
                        name="Country"
                        rules="required"
                      >
                        <b-form-group
                          label="Country"
                          label-for="country"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            id="country"
                            v-model.lazy="countrySelect[index]"
                            v-on:input="countrySelected(index)"
                            :state="countrySelect[index] == null ? false : true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="countryOptions"
                            :selectable="
                              (option) => !option.value.includes('select_value')
                            "
                            label="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- city -->
                    <b-col md="4">
                      <validation-provider
                        #default="{ errors }"
                        name=' " city " + [index] '
                        rules="required"
                      >
                        <b-form-group
                          label="City"
                          label-for="city"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            :id="' city ' + [index]"
                            v-model="citySelect[index]"
                            @input="citySelected(index)"
                            :disabled="city[index].disable"
                            :state="citySelect[index] == null ? false : true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="cityOptions[index]"
                            :selectable="
                              (option) => !option.value.includes('select_value')
                            "
                            label="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- area -->
                    <b-col md="4">
                      <validation-provider
                        #default="{ errors }"
                        name="Area"
                        rules="required"
                      >
                        <b-form-group
                          label="Area"
                          label-for="area"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            id="area"
                            v-model="areaSelect[index]"
                            :disabled="area[index].disable"
                            :state="areaSelect[index] == null ? false : true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="areaOptions[index]"
                            :selectable="
                              (option) => !option.value.includes('select_value')
                            "
                            label="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Price" label-for="price">
                        <validation-provider
                          #default="{ errors }"
                          name="price"
                          rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                        >
                          <b-form-input
                            id="price"
                            v-model="item.price"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Remove Button -->
                    <b-col lg="4" md="4" class="mb-50">
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removeItem(index)"
                      >
                        <feather-icon icon="XIcon" class="mr-25" />
                        <span>Remove</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </div>
            </b-col>
            <!-- add new button -->
            <b-col sm="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                block
                @click="repeateAgain"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add New</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!--submit button -->
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body" v-if="errors_back != null">
        <span>{{ errors_back }}</span>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'
import { required, integer } from '@validations'
export default {
  components: {
    BCardCode,
    VueEditor,
  },
  
  mixins: [heightTransition],
  data() {
    return {
      showDismissibleAlert: false,
      activeSelect: null,
      activeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: '0',
          text: 'False',
        },
        {
          value: '1',
          text: 'True',
        },
      ],
      area: [
        {
          disable: true,
        },
      ],
      city: [
        {
          disable: true,
        },
      ],
      countryOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      countrySelect: [null],
      cityOptions: [
        [
          {
            value: 'select_value',
            text: 'Select Value',
          },
        ],
      ],
      citySelect: [null],
      areaOptions: [[]],
      areaSelect: [null],
      tranLocaleSelect: [
        {
          value: 'en',
          text: 'English',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
      ],
      localeOptions: [
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'ar',
          text: 'Arabic',
        },
      ],
      courierData: {
        translations: [
          {
            locale: '',
            name: '',
            description: '',
          },
          {
            locale: '',
            name: '',
            description: '',
          },
        ],
        active: '',
        areas: [
          {
            area_id: '',
            price: '',
          },
        ],
      },
      errors_back: [],
      required,
      integer,
      countryIndex: null,
    }
  },
  watch: {},
  methods: {
    arrayDiff(a, b) {
      return [...a.filter((x) => !b.includes(x))]
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.courierData.active = this.activeSelect.value
          for (let ind in this.areaSelect) {
            this.courierData.areas[ind].area_id = this.areaSelect[ind].value
          }
          for (let trn in this.courierData.translations) {
            this.courierData.translations[trn].locale = this.tranLocaleSelect[
              trn
            ].value
          }
          axios
            .post('couriers', this.courierData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.push('/Courier/Index')
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    // REPETED FORM
    repeateAgain() {
      this.courierData.areas.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
      this.citySelect.push(null)
      this.countrySelect.push(null)
      this.areaSelect.push(null)
      this.cityOptions.push([
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ])
      this.areaOptions.push([])
      this.city.push({
        disable: true,
      })
      this.area.push({
        disable: true,
      })
    },
    removeItem(index) {
      if (this.courierData.areas.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.courierData.areas.splice(index, 1)
        this.citySelect.splice(index, 1)
        this.countrySelect.splice(index, 1)
        this.areaSelect.splice(index, 1)
        this.areaOptions.splice(index, 1)
        this.cityOptions.splice(index, 1)
        this.area.splice(index, 1)
        this.city.splice(index, 1)
      }
    },
    removeItem2(index) {
      if (this.courierData.translations.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.courierData.translations.splice(index, 1)
        this.tranLocaleSelect.splice(index, 1)
      }
    },
    repeateAgain2() {
      this.courierData.translations.push({})
      this.tranLocaleSelect.push({})
    },
    countrySelected(indx) {
      axios
        .get('country/' + this.countrySelect[indx].value + '/cities')
        .then((result) => {
          const data = result.data.data
          this.city[indx].disable = false
          this.cityOptions[indx].length = 0
          for (let ind in data) {
            this.cityOptions[indx].push({
              text: data[ind].name,
              value: data[ind].id.toString(),
            })
          }
          this.$emit('cityOptions', this.cityOptions)
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    citySelected(indx) {
      axios
        .get('cities/' + this.citySelect[indx].value + '/areas')
        .then((result) => {
          const data = result.data.data
          this.area[indx].disable = false
          this.areaOptions[indx].length = 0
          for (let ind in data) {
            this.areaOptions[indx].push({
              text: data[ind].name,
              value: data[ind].id.toString(),
            })
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
  created() {
    axios
      .get('countries')
      .then((result) => {
        const data = result.data.data
        for (let ind in data) {
          this.countryOptions.push({
            text: data[ind].name,
            value: data[ind].id.toString(),
          })
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
}
</script>

<style lang="scss">

</style>
